import { render, staticRenderFns } from "./SettingConfig.vue?vue&type=template&id=72b3406c&scoped=true&"
import script from "./SettingConfig.vue?vue&type=script&lang=js&"
export * from "./SettingConfig.vue?vue&type=script&lang=js&"
import style0 from "./SettingConfig.vue?vue&type=style&index=0&id=72b3406c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72b3406c",
  null
  
)

export default component.exports